@mixin vh-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin skeleton-animation {
  animation: skeleton-gradient 1.5s infinite ease-in-out;
}

//skeleton 애니메이션
@keyframes skeleton-gradient {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.1;
  }
}
