.delete-failed-root {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;

  background-color: #0c0c0f;

  .desc {
    color: #f9f9f6;
  }
}
