$white: #fff;
$black: #000;
$bg100: #e3d1ab;
$bg200: #fdf7f1;
$bg300: #ddd9ce;
$shadow: #343434;
$text100: #010101;
$border: #75706c;
$blue: #7aa4d5;
$red: #d15e60;
$yellow: #d5d17a;
$green: #67d15e;

:export {
  white: $white;
  black: $black;
  bg100: $bg100;
  bg200: $bg200;
  bg300: $bg300;
  shadow: $shadow;
  text100: $text100;
  border: $border;
  blue: $blue;
  red: $red;
  yellow: $yellow;
  green: $green;
}
