@import 'src/styles/mixin';

.delete-account-root {
  width: 100vw;
  height: 100vh;
  background-color: #303030;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 36px 20px;

  .notice {
    display: flex;
    flex-direction: column;
    padding: 16px;
    background-color: #0c0c0f;
    border-radius: 4px;
    gap: 6px;
    margin-bottom: 12px;
    min-width: 180px;

    h3,
    p {
      color: #f9f9f6;
    }

    h3 {
      margin-bottom: 8px;
    }
  }

  .check-box-wrapper {
    justify-content: flex-start;
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 24px;

    p {
      color: #f9f9f6;
      size: 24px;
    }
  }

  .kakao-btn,
  .google-btn {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 0px 12px;
    min-width: 180px;
    max-width: 200px;
    width: 100%;
    border-radius: 2px;
    font-weight: bold;
  }

  .kakao-btn {
    background-color: #ffeb00;
    color: #3c1e1e;
    margin-bottom: 16px;
  }

  .google-btn {
    background-color: #f5f5f5;
    border: 1px solid #cccccc;
    color: #3e3d3e;
  }
}
