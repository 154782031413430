@import 'src/styles/colors.module';
@import 'src/styles/mixin';

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $text100;
  letter-spacing: -0.6px;
  touch-action: pan-y;
  font-family: 'Spoqa Han Sans Neo', 'sans-serif';
}

input {
  outline: none;
  background-color: transparent;
}

button {
  cursor: pointer;
  outline: none;
  background-color: transparent;
}

button:disabled {
  cursor: default;
}

html {
  font-size: 16px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

#root {
  @include vh-center;
  height: 100vh;
  background-color: $bg100;
}
